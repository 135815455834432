<template>
  <div>
    <show-case :sku="santeBar" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import SanteBarBanana from "../assets/Sante/SanteBar/Chocolate-coated_crunchy_bar_with_bananas_40g.jpg";
import SanteBarAlmond from "../assets/Sante/SanteBar/Chocolate-coated_Crunchy_Bar_with_nuts_and_almonds_40g.jpg";
import SanteBarNuts from "../assets/Sante/SanteBar/Crunchy_Bar_with_nuts_and_almonds_35g.jpg";
import SanteBarCranberry from "../assets/Sante/SanteBar/Vanilla-coated_Crunchy_Bar_with_cranberry_and_raspberry_40g.jpg";
import SanteBarVanila from "../assets/Sante/SanteBar/Vanilla-coated_Crunchy_Bar_with_plums_40g.jpg";

export default {
  name: "SanteBar",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Батончики мюсли",
      santeBar: [
        {
          picture: SanteBarBanana,
          text: "Sante Батончик мюсли Crunchy с бананом в шоколаде 40&nbspг",
          id: 1,
          description:
            "Состав: злаковые продукты 34,5% (овсяные хлопья, кукурузная мука, кукурузная крупа, рисовая мука), глюкозный сироп, шоколад 18% (какао тертое, сахар, какао масло, эмульгатор: соевый лецитин; натуральный ароматизатор ваниль), виноград, пальмовое масло, банановое пюре 3,4%, влагоудерживающий агент: сорбит; кокосовые хлопья, тростниковый сахар, натуральный ароматизатор банан, регулятор кислотности: лимонная кислота; сахар, соль, эмульгатор: соевый лецитин; морская соль, экстракт ячменного солода.<br>Продукт может содержать следы арахиса, других орехов и кунжута.<br>Пищевая ценность в 100 г: белки 5,0г; углеводы 67,0г, из них сахара 28,0г, полиолы 1,7г; жиры 14,0г, из них насыщенные 7,0г; клетчатка 4,4г, соль 0,2г.<br>Энергетическая ценность 420 Ккал.<br>Хранить в сухом месте при температуре не более +26С и относительной влажности воздуха не более 70%. Срок годности 9 месяцев.<br>Страна изготовитель: Польша",
        },
        {
          picture: SanteBarAlmond,
          text: "Sante Батончик мюсли Crunchy с орехами и миндалем в шоколаде 40&nbspг",
          id: 2,
          description:
            "Состав: орехи 27,7% (арахис, фундук), глюкозный сироп, злаковые продукты 19% (овсяные хлопья, кукурузная мука, рисовая мука), шоколад 18% (какао тертое, сахар, какао масло, эмульгатор: соевый лецитин; натуральный ароматизатор ваниль), пальмовое масло, влагоудерживающий агент: сорбит; миндальные хлопья 2%, глюкоза кристаллическая, тростниковый сахар, натуральный ароматизатор орех, регулятор кислотности: лимонная кислота; эмульгатор: соевый лецитин; морская соль.<br>Продукт может содержать следы других орехов и молока<br><br>Пищевая ценность в 100 г: белки 10,0г; углеводы 49,0г, из них сахара 23,0г, полиолы 2,4г; жиры 26,0г, из них насыщенные 8,0г; клетчатка 5,1г, соль 0,05г.<br>Энергетическая ценность 476 Ккал.<br>Хранить в сухом месте при температуре не более +26С и относительной влажности воздуха не более 70%. Срок годности 9 месяцев.<br>Страна изготовитель: Польша",
        },
        {
          picture: SanteBarNuts,
          text: "Sante Батончик мюсли Crunchy с орехами 35&nbspг",
          id: 3,
          description:
            "Состав: орехи 34% (арахис, фундук), глюкозный сироп, злаковые продукты 24% (овсяные хлопья, кукурузная мука, рисовая мука), пальмовое масло, влагоудерживающий агент: сорбит; миндаль 2,5%; тростниковый сахар, натуральный ароматизатор орех, эмульгатор: соевый лецитин, морская соль.<br>Продукт может содержать следы других орехов и молока.<br>Пищевая ценность в 100 г: белки 12,0г; углеводы 51,0г, из них сахара 18,0г, полиолы 2,6г; жиры 24,0г, из них насыщенные 4,8г; клетчатка 4,6г, соль 0,1г.<br>Энергетическая ценность 473 Ккал.<br>Хранить в сухом месте при температуре не более +26С и относительной влажности воздуха не более 70%. Срок годности 9 месяцев.<br>Страна изготовитель: Польша",
        },
        {
          picture: SanteBarCranberry,
          text: "Sante Батончики мюсли Crunchy с клюквой и малиной в ванильной глазури 40&nbspг",
          id: 4,
          description:
            "Состав: злаковые продукты 30% (овсяные хлопья, кукурузная крупа, кукурузная мука, рисовая мука), глюкозный сироп, сушеная подслащенная клюква 10,7% (клюква, тростниковый сахар, подсолнечное масло), растительные жиры (пальмовое масло, масло пальмовых семян, масло ши) в различных пропорциях; сахар, сухая молочная сыворотка, влагоудерживающий агент: сорбит; тростниковый сахар, регулятор кислотности: лимонная кислота; сублимированная малина 0,27%, эмульгатор: соевый лецитин, соль, натуральный ароматизатор клюква, экстракт ячменного солода, морская соль, натуральный ароматизатор ваниль.<br>Продукт может содержать следы арахиса, других орехов и кунжута.<br>Пищевая ценность в 100 г: белки 3,7г; углеводы 72,0г, из них сахара 36,0г, полиолы 2,0г; жиры 13,0г, из них насыщенные 8,1г; клетчатка 2,6г, соль 0,3г<br>Энергетическая ценность 422 Ккал.<br>Хранить в сухом месте при температуре не более +26С и относительной влажности воздуха не более 70%. Срок годности 9 месяцев.<br>Страна изготовитель: Польша",
        },
        {
          picture: SanteBarVanila,
          text: "Sante Батончики мюсли Crunchy со сливой в ванильной глазури 40&nbspг",
          id: 5,
          description:
            "Состав: злаковые продукты 33% (овсяные хлопья, кукурузная крупа, кукурузная мука, рисовая мука), глюкозный сироп, растительные жиры (пальмовое масло, масло пальмовых семян, масло ши) в различных пропорциях; сахар, сушеные сливы 10% (сливы, рисовая мука), сухая молочная сыворотка, влагоудерживающий агент: сорбит; тростниковый сахар, натуральный ароматизатор слива, регулятор кислотности: лимонная кислота; эмульгатор: соевый лецитин, морская соль, соль, экстракт ячменного солода, натуральный ароматизатор ваниль.<br>Продукт может содержать следы арахиса, других орехов и кунжута<br>Пищевая ценность в 100 г: белки 4,1г; углеводы 69,0г, из них сахара 34,0г, полиолы 1,7г; жиры 13,0г, из них насыщенные 7,8г; клетчатка 2,8г, соль 0,4г.<br>Энергетическая ценность 412 Ккал.<br>Хранить в сухом месте при температуре не более +26С и относительной влажности воздуха не более 70%. Срок годности 9 месяцев.<br>Страна изготовитель: Польша",
        },
      ],
    };
  },
};
</script>
